import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import NumberTicker from "components/NumberTicker";

interface MetricCardProps {
    metricTitle: string;
    metricValue: number | string;
    id:number
}

const MetricCard: React.FC<MetricCardProps> = ({ metricTitle, metricValue, id }) => {
    return (
        <Grid item xs={12} sm={6}>
        <Card elevation={3} sx={{ height: "auto", minHeight: 125, borderRadius: "15px" }}>
            <CardContent>
                <Typography variant="h5" gutterBottom>
                    {metricTitle}
                </Typography>
                <Typography variant="h6" color="text.primary" sx={{ mt: 1 }}>
                    {typeof metricValue === 'number' ? <NumberTicker value={metricValue} /> : metricValue}
                </Typography>
            </CardContent>
        </Card>
        </Grid>
    );
};

export default MetricCard;