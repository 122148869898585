import React from "react";
import { PieChart, Pie, Cell, Tooltip, BarChart, Bar, XAxis, YAxis, Legend, ResponsiveContainer, CartesianGrid } from "recharts";
import { ValueType } from "recharts/types/component/DefaultTooltipContent";

type SupportedChart = "pie" | "bars";

const defaultFormatter = (v: any) => v

interface AxisOptions<T> {
  label?: string;
  tickFormatter?: (value: unknown, index: number) => string;
  dataKey?: keyof T
}

export interface ChartOptions<T> {
  data: T[];
  type: SupportedChart;
  percentageLabel?: boolean;
  tooltipFormatter?: (value: ValueType) => React.ReactNode;
  nameKey?: keyof T;
  dataKey?: keyof T;
  axis?: {
    x?: AxisOptions<T>,
    y?: AxisOptions<T>
  }
}

interface ChartProps<T> {
  options: ChartOptions<T>;
}

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#BD67A0", "#FC381B"];

function Chart<T>({ options }: ChartProps<T>) {

  if (options.type === "pie") {
    return (
      <ResponsiveContainer width="100%" height="100%" style={{ overflow: "hidden" }}>
        <PieChart>
          <Pie
            data={options.data}
            dataKey={options.dataKey as string}
            nameKey={options.nameKey as string}
            cx="50%"
            cy="50%"
            outerRadius="85%"
            label={({ name }) => name}
          >
            {options.data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip formatter={options.tooltipFormatter || defaultFormatter} />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
    );
  }

  else {
    return (
      <ResponsiveContainer width="100%" height="95%">
        <BarChart data={options.data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={options.axis?.x?.dataKey as string || ""}
            tickFormatter={options.axis?.x?.tickFormatter || defaultFormatter}
            label={{
              value: options.axis?.x?.label || "",
            }} />
          <YAxis
            label={{
              value: options.axis?.y?.label || "",
              angle: -90,
              position: "insideLeft",
              style: { textAnchor: "middle" },
            }}
            tickFormatter={options.axis?.y?.tickFormatter || defaultFormatter}
            dataKey={options.axis?.y?.dataKey as string || ""}
          />
          <Tooltip
            labelFormatter={options.tooltipFormatter || defaultFormatter}
          />
          <Bar name={options.axis?.y?.label || ""} dataKey={options.axis?.y?.dataKey as string} fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

export default Chart;
