import React, { Key, useState } from "react";
import {
  IconButton,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useSortableList } from "hooks";
import { defaultSortFn, SortFn, SortState } from "hooks/useSortableList";
import { ArrowDownward, ArrowUpward, Sort } from "@mui/icons-material";
import { SupportedUserDataTypes } from "hooks/useUserDataKeys";
import dayjs from "dayjs";

interface Column<T> {
  id: string | number;
  type?: SupportedUserDataTypes;
  name: React.ReactNode;
  field: keyof T;
  disableSort?: boolean;
  renderCell?: (params: {
    row: T;
    value: unknown;
    index: number;
  }) => React.ReactNode;
}
export interface TableOptions<T> {
  data: T[];
  columns: Column<T>[];
  keyGetter: (row: T) => Key | null | undefined;
}

interface TableProps<T> {
  options: TableOptions<T>;
}
interface CellProps<T> {
  col: Column<T>;
  row: T;
  index: number;
}
function sortNumbers<T>(a: T, b: T, property: keyof T) {
  return (a[property] as number) - (b[property] as number);
}
function sortDates<T>(a: T, b: T, property: keyof T) {
  const aValid =
    dayjs(a[property] as string, "YYYY-MM-DD").isValid() &&
    isNaN(Number(a[property]));
  const bValid =
    dayjs(b[property] as string, "YYYY-MM-DD").isValid() &&
    isNaN(Number(b[property]));
  if (aValid && bValid) {
    return dayjs(a[property] as string, "YYYY-MM-DD").diff(
      dayjs(b[property] as string, "YYYY-MM-DD")
    );
  } else if (aValid) {
    return -1;
  } else {
    return 1;
  }
}
function getSortFn<T>(type: SupportedUserDataTypes): SortFn<T> {
  const dict = {
    [SupportedUserDataTypes.string]: defaultSortFn<T>,
    [SupportedUserDataTypes.number]: sortNumbers<T>,
    [SupportedUserDataTypes.date]: sortDates<T>,
  };
  return dict[type];
}
function Cell<T>(props: CellProps<T>) {
  const { col, index, row } = props;

  return (
    <TableCell>
      {col.renderCell
        ? col.renderCell({ row, value: row[col.field], index })
        : (row[col.field] as React.ReactNode)}
    </TableCell>
  );
}

function Table<T>(props: TableProps<T>) {
  const {
    options: { columns, data, keyGetter },
  } = props;

  const [sort, setSort] = useState<{
    key: keyof T;
    direction: SortState;
    type: SupportedUserDataTypes;
  }>({
    key: columns[0].field,
    direction: SortState.None,
    type: columns[0].type || SupportedUserDataTypes.string,
  });

  const sorted = useSortableList(
    data,
    sort.key,
    sort.direction,
    getSortFn(sort.type)
  );

  const handleSort = (col: Column<T>) => {
    setSort((prev) => {
      if (prev.direction === SortState.None) {
        return {
          direction: SortState.Asc,
          key: col.field,
          type: col.type || SupportedUserDataTypes.string,
        };
      }
      if (prev.key !== col.field) {
        return {
          direction: SortState.Asc,
          key: col.field,
          type: col.type || SupportedUserDataTypes.string,
        };
      } else {
        if (prev.direction === SortState.Asc) {
          return {
            direction: SortState.Desc,
            key: col.field,
            type: col.type || SupportedUserDataTypes.string,
          };
        } else {
          return {
            direction: SortState.None,
            key: col.field,
            type: col.type || SupportedUserDataTypes.string,
          };
        }
      }
    });
  };
  return (
    <MuiTable stickyHeader>
      <TableHead>
        <TableRow>
          {columns.map((col) => (
            <TableCell key={`col-${col.id}`}   
            sx={{
              fontWeight: "bold",
               whiteSpace: "nowrap",
            }}
          >
              {col.name}
              <IconButton
                sx={{
                  opacity:
                    sort.key === col.id && sort.direction !== SortState.None
                      ? 1
                      : 0,
                  ":hover": { opacity: 1 },
                  transition: "all",
                }}
                onClick={() => handleSort(col)}
              >
                {sort.key !== col.field ? (
                  <Sort />
                ) : sort.direction === SortState.None ? (
                  <Sort />
                ) : sort.direction === SortState.Asc ? (
                  <ArrowDownward />
                ) : (
                  <ArrowUpward />
                )}
              </IconButton>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sorted.map((row, index) => (
          <TableRow key={`row-${keyGetter(row)}`}>
            {columns.map((col) => (
              <Cell
                key={`col-${col.id}-${keyGetter(row)}`}
                col={col}
                row={row}
                index={index}
              />
            ))}
          </TableRow>
        ))}
      </TableBody>
    </MuiTable>
  );
}

export default Table;
