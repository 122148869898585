import { useEffect, useMemo, useState } from "react";

export enum SortState {
  None,
  Asc,
  Desc,
}
export type SortFn<T> = (a: T, b: T, property: keyof T) => number;

export function defaultSortFn<T>(a: T, b: T, property: keyof T) {
  return (a[property] as string).localeCompare(b[property] as string);
}

const useSortableList = <T>(
  _list: Array<T>,
  property: keyof T,
  sort: SortState,
  fnSort: SortFn<T> = defaultSortFn
) => {
  const [sortedList, setSortedList] = useState<Array<T>>([]);
  const list = useMemo(() => _list, [_list]);

  useEffect(() => {
    if ([SortState.Asc, SortState.Desc].includes(sort)) {
      setSortedList(
        [...list].sort((a, b) =>
          sort === SortState.Asc
            ? fnSort(a, b, property)
            : -fnSort(a, b, property)
        )
      );
    }
    if (sort === SortState.None) {
      setSortedList([...list]);
    }
  }, [sort, list, property, fnSort]);

  return sortedList;
};

export default useSortableList;
