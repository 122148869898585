import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { useBreakpoint, useForm, useTranslations } from "hooks";
import { isRequired, isSame, Validation } from "hooks/useForm";
import { getTranslation } from "common";
import { usersApi } from "api";
import { validatePasswordStrength } from "common/utilities";

interface Props {
  onClose: any;
  admin?: boolean;
  uID?: number;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const PasswordDialog: React.FC<Props> = ({
  open,
  setOpen,
}) => {
  const navigate = useNavigate();
  const translations = useTranslations();
  const [showCurrent, setShowCurrent] = useState<boolean>(false);
  const [showNew, setShowNew] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const validations: Validation[] = [
    ({ new: _ }) =>
      isRequired(_) || {
        new: getTranslation(translations, "register.fieldrequired"),
      },
    ({ current: _ }) =>
      isRequired(_) || {
        current:
          getTranslation(translations, "register.fieldrequired"),
      },
    ({ confirm: _ }) =>
      isRequired(_) || {
        confirm:
          getTranslation(translations, "register.fieldrequired"),
      },
    ({ new: _new, confirm: _ }) =>
      isSame(_, _new) || {
        confirm:
          getTranslation(translations, "register.passwordnotequal"),
      },
    ({ new: _new }) => {
      const passwordError = validatePasswordStrength(_new, translations);
      if (passwordError) {
        return { new: passwordError };
      }
      return true;
    }
  ];
  const isMobile = useBreakpoint("down", "sm");

  const { values, changeHandler, touched, errors, isValid } = useForm(
    {
      new: "",
      current: "",
      confirm: "",
    },
    validations
  );

  const submitData = async () => {
    const { new: _new, current, confirm } = values;
    // if (onClose == "() => {}") {
    //   void axios.post(`${url}users/changepassword/${jwt}`, {
    //     password1: textPassword1,
    //     password2: textPassword2,
    //   }).then(() => {
    //     void axios.post(`${url}users/${jwt}/accept`, {
    //       idorganization: idOrg
    //     }).then(() => {
    //       navigate("/login");
    //       onClose();
    //     })
    //   }).catch(() => {
    //     setOpenPopup(true);
    //   });

    // } else {
    try {
      await usersApi.post(`auth/changepassword`, {
        oldpassword: current,
        password1: _new,
        password2: confirm,
      });
      navigate("/login");
      setOpen(false);
    } catch {
    } finally {
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullScreen={isMobile}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
          justifyContent="space-between"
        >
          {getTranslation(translations, "password.name")}
          <IconButton onClick={() => setOpen(false)}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Grid container component="form" pt={0.7} spacing={2}>
          <Grid item xs={12} sm={8}>
            <input
              type="text"
              name="email"
              autoComplete="username email"
              style={{ display: "none" }}
            />
            <TextField
              fullWidth
              id="current-password"
              type={showCurrent ? "text" : "password"}
              autoComplete="current-password"
              size="small"
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowCurrent(_ => !_)}>
                      {showCurrent ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              value={values.current || ""}
              error={Boolean(touched.current && errors.current)}
              helperText={touched.current && errors.current}
              onChange={({ target }) => changeHandler("current", target.value)}
              label={getTranslation(
                translations,
                "password.label.currentpassword"
              )}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              id="new-password"
              fullWidth
              type={showNew ? "text" : "password"}
              autoComplete="new-password"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowNew(_ => !_)}>
                      {showNew ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              value={values.new || ""}
              error={Boolean(touched.new && errors.new)}
              helperText={touched.new && errors.new}
              onChange={({ target }) => changeHandler("new", target.value)}
              label={getTranslation(translations, "password.label.newpassword")}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextField
              id="confirm-new-password"
              fullWidth
              type={showConfirm ? "text" : "password"}
              autoComplete="confirm-password"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowConfirm(_ => !_)}>
                      {showConfirm ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="outlined"
              value={values.confirm || ""}
              error={Boolean(touched.confirm && errors.confirm)}
              helperText={touched.confirm && errors.confirm}
              onChange={({ target }) => changeHandler("confirm", target.value)}
              label={getTranslation(
                translations,
                "password.label.confirmpassword"
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button
            id="change-password-btn"
            onClick={submitData}
            variant="contained"
            size="small"
            disabled={!isValid}
          >
            {getTranslation(translations, "generic.button.savechanges")}
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => setOpen(false)}
          >
            {getTranslation(translations, "generic.button.cancel")}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default PasswordDialog;
