import {
  TableChart,
  Close,
  Poll,
  Download,
  OpenInFull,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Paper,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { DynamicChart, DynamicTable } from "./dynamic";
import { TableOptions } from "./dynamic/Table";
import { ChartOptions } from "./dynamic/Chart";
import { getTranslation } from "common";
import { useTranslations } from "hooks";

interface CardGraphDetailProps<T, K> {
  title: string;
  chartOptions?: ChartOptions<K>;
  tableOptions?: TableOptions<T>;
  cardId?: string;
  interval?: { start: dayjs.Dayjs | null; end: dayjs.Dayjs | null };
}

function CardGraphDetail<T, K>({
  title,
  chartOptions,
  tableOptions,
  cardId,
  interval,
}: CardGraphDetailProps<T, K>) {
  const hasOnlyChart = Boolean(!tableOptions && chartOptions);
  const hasBothOptions = Boolean(tableOptions && chartOptions);
  const hasOneOption = Boolean(tableOptions || chartOptions);
  const translations = useTranslations();

  const [showChart, setShowChart] = useState<boolean>(hasOnlyChart);
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);
  const [invisible, setInvisible] = useState(false)
  const [targetElementId, setTargetElementId] = useState<string>('');

  useEffect(() => {
    const savedState = localStorage.getItem(`card-${cardId}`);
    if (savedState) {
      setShowChart(savedState === "true");
    }
  }, [cardId]);

  const toggleChart = () => {
    setShowChart(!showChart);
    localStorage.setItem(`card-${cardId}`, String(!showChart));
  };

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const handlePdfDownload = async (targetId: string) => {
    setInvisible(true);
    setTargetElementId(targetId);
  };
  useEffect(() => {
    (async () => {
      if (targetElementId && invisible) {
        const content = document.querySelector(`#${targetElementId}`);
        if (content) {
          const canvasFromContent = await html2canvas(content as HTMLElement);
          const imgData = canvasFromContent.toDataURL("image/png");
          setInvisible(false)
          const pdf = new jsPDF({
            orientation: "landscape",
            unit: "px",
            format: [canvasFromContent.width, canvasFromContent.height],
          });

          pdf.addImage(
            imgData,
            "PNG",
            0,
            0,
            canvasFromContent.width,
            canvasFromContent.height
          );
          pdf.save(`${title || "document"}.pdf`);
        }
      }
    })()
  }, [invisible, title, targetElementId]);

  if (!hasOneOption) {
    return (
      <Card
        elevation={3}
        component={Paper}
        sx={{
          position: "relative",
          padding: 2,
          mt: 2,
        }}
      >
        <CardHeader>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h5" gutterBottom>
              {title}
            </Typography>
          </Box>
        </CardHeader>
        <CardContent>{getTranslation(translations, "no_content")}</CardContent>
      </Card>
    );
  }
  return (
    <>
      <Card
        elevation={3}
        component={Paper}
        sx={{
          position: "relative",
          padding: 2,
          mt: 2,
        }}
      >
        {hasBothOptions && (
          <IconButton
            onClick={toggleChart}
            sx={{
              position: "absolute",
              top: 16,
              right: 48,
              color: "rgba(84, 189, 193, 1)",
              borderRadius: "6px",
            }}
          >
            {showChart ? <TableChart /> : <Poll />}
          </IconButton>
        )}
        <IconButton
          onClick={handleDialogOpen}
          sx={{
            position: "absolute",
            top: 16,
            right: 16,
            color: "rgba(84, 189, 193, 1)",
            borderRadius: "6px",
          }}
        >
          <OpenInFull />
        </IconButton>
        <IconButton
          onClick={() => handlePdfDownload('card-content')}
          sx={{
            position: "absolute",
            top: 16,
            right: 80,
            color: "rgba(84, 189, 193, 1)",
            borderRadius: "6px",
          }}
        >
          <Download />
        </IconButton>
        <Box id="card-content" sx={{ mt: 4 }}>
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>
          <Box sx={{ height: 450, overflowY: "auto" }}>
            {showChart
              ? chartOptions && <DynamicChart options={chartOptions} />
              : tableOptions && <DynamicTable options={tableOptions} />}
          </Box>
        </Box>
      </Card>
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        fullScreen
        id="dialog-content"
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {interval && interval.start && interval.end && (
              <Typography variant="h6">
                {title} -{" "}
                {`${getTranslation(translations, "generic.interval")}: ${interval.start.format(
                  "DD/MM/YYYY"
                )} - ${interval.end.format("DD/MM/YYYY")}`}
              </Typography>
            )}
            <Box sx={{ opacity: invisible ? 0 : 1 }}>
              <IconButton
                sx={{
                  color: "rgba(84, 189, 193, 1)",
                  borderRadius: "6px",
                }}
                onClick={() => handlePdfDownload('dialog-content')}
              >
                <Download />
              </IconButton>
              {/* <IconButton
                sx={{
                  color: "rgba(84, 189, 193, 1)",
                  borderRadius: "6px",
                }}
              >
                <ShareIcon />
              </IconButton> */}
              {hasBothOptions && (
                <IconButton
                  onClick={toggleChart}
                  sx={{
                    color: "rgba(84, 189, 193, 1)",
                    borderRadius: "6px",
                  }}
                >
                  {showChart ? <TableChart /> : <Poll />}
                </IconButton>
              )}
              <IconButton
                onClick={handleDialogClose}
                sx={{
                  color: "rgba(84, 189, 193, 1)",
                  borderRadius: "6px",
                }}
              >
                <Close />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ height: "100%", overflowY: "auto" }}>
            {showChart
              ? chartOptions && <DynamicChart options={chartOptions} />
              : tableOptions && <DynamicTable options={tableOptions} />}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CardGraphDetail;
