import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import MetricCard from "./dialogs/MetricCard";
import { formatDuration, getTranslation } from "common/utilities";
import { FlatStatistics } from "./PerformanceView";
import dayjs from "dayjs";
import { useDurationFormat, useTranslations } from "hooks";

interface PerformanceViewHeaderProps {
  data: FlatStatistics[] | null | undefined;
}

const PerformanceViewHeader: React.FC<PerformanceViewHeaderProps> = ({
  data,
}) => {
  const translations = useTranslations();
  const { replaceDatePlaceHolder } = useDurationFormat();
  const isValidData = Array.isArray(data) && data.length > 0;

  const totalActivities = useMemo(() => {
    if (!isValidData) return 0;
    return data.length;
  }, [data, isValidData]);

  const uniqueCompletedProcesses = useMemo(() => {
    if (!isValidData) return [];
    return Array.from(new Set(data.map((item) => item.idprocess)));
  }, [data, isValidData]);

  const totalCompletedProcesses = useMemo(() => {
    return uniqueCompletedProcesses.length;
  }, [uniqueCompletedProcesses]);

  const averageActivityTime = useMemo(() => {
    if (!isValidData) return 0;
    const totalWorkingSeconds = data.reduce((sum, task) => {
      const duration = task.timeindoing || 0;
      return sum + duration;
    }, 0);
    return data.length > 0 ? totalWorkingSeconds / data.length : 0;
  }, [data, isValidData]);

  const averageProcessTime = useMemo(() => {
    if (!isValidData) return 0;

    const totalProcessTime = uniqueCompletedProcesses.reduce(
      (sum, idprocess) => {
        const process = data.find((item) => item.idprocess === idprocess);
        // console.log("process", process)
        // console.log("processduration", process?.processduration)
        return sum + (process?.processduration || 0);
      },
      0
    );

    // console.log("uniqueCompletedProcesses.length", uniqueCompletedProcesses.length)

    return uniqueCompletedProcesses.length > 0
      ? totalProcessTime / uniqueCompletedProcesses.length
      : 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isValidData, uniqueCompletedProcesses]);

  return (
    <Grid container spacing={2}>
      <MetricCard
        metricTitle={getTranslation(translations, "statistics_total.completed.processes")}
        metricValue={totalCompletedProcesses}
        id={2}
      />
      <MetricCard
        metricTitle={getTranslation(translations, "statistics_total.completed.activities")}
        metricValue={totalActivities}
        id={1}
      />
      <MetricCard
        metricTitle={getTranslation(translations, "statistics_average.time.per.process")}
        metricValue={replaceDatePlaceHolder(
          dayjs
            .duration(averageProcessTime || 0, "seconds")
            .format(formatDuration(averageProcessTime))
        )}
        id={4}
      />
      <MetricCard
        metricTitle={getTranslation(translations, "statistics_average.time.per.activity")}
        metricValue={replaceDatePlaceHolder(
          dayjs
            .duration(averageActivityTime || 0, "seconds")
            .format(formatDuration(averageActivityTime))
        )}
        id={3}
      />
    </Grid>
  );
};
export default PerformanceViewHeader;
