import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslations, useForm, useSnackbar } from "hooks";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  IconButton,
  Grid,
  TextField,
  Button,
  Container,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Box,
  Typography,
} from "@mui/material";
import { usersApi } from "api";
import { getTranslation } from "common";
import { Validation, isRequired } from "hooks/useForm";
import { validatePasswordStrength } from "common/utilities";

const Password: React.FC = () => {
  const navigate = useNavigate();
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] =
    useState(false);
  const translations = useTranslations();

  const { sendSnack } = useSnackbar();
  const [searchParams] = useSearchParams();
  const jwt = searchParams.get("jwt");
  const idOrganization = searchParams.get("idorganization");
  const send = searchParams.get("send");

  useEffect(() => {
    if (!jwt) {
      navigate("/login");
    }
  }, [jwt, idOrganization, navigate]);

  const validations: Validation[] = [
    ({ password }) =>
      isRequired(password) || {
        password: getTranslation(translations, "register.fieldrequired"),
      },
    ({ confirmPassword }) =>
      isRequired(confirmPassword) || {
        confirmPassword: getTranslation(translations, "register.fieldrequired"),
      },
    ({ password }) => {
      const passwordError = validatePasswordStrength(password, translations);
      if (passwordError) {
        return { password: passwordError };
      }
      //null case
      return true;
    }
  ];
  const { setValues, values, changeHandler, isValid, errors, touched } =
    useForm(
      {
        password: "",
        confirmPassword: "",
      },
      validations
    );

  const submitData = async (data: {
    password: string;
    confirmPassword: string;
  }) => {
    const { password, confirmPassword } = data;
    if (password !== confirmPassword) {
      setValues({ confirmPassword: "", password });
      sendSnack({
        message: getTranslation(translations, "register.passwordnotequal"),
        type: "error",
      });
      return;
    }
    let qParams = "";
    if (idOrganization) {
      qParams += `?idorganization=${idOrganization}`;
      if (send) {
        qParams += `&send=${send}`;
      }
    } else if (send) {
      qParams += `?send=${send}`;
    }

    await usersApi.post(`changepassword/${jwt}${qParams}`, {
      password1: password,
      password2: confirmPassword,
    });
    if (idOrganization) {
      if (isNaN(Number(idOrganization))) {
        sendSnack({
          type: "error",
          message: getTranslation(
            translations,
            "generic.something_went_wrong.caption"
          ),
        });
      } else {
        await usersApi.post(`${jwt}/accept`, {
          idorganization: idOrganization,
        });
      }
    }
    navigate("/login");
  };
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
      }}
    >
      <Container
        maxWidth="sm"
        sx={{
          justifyContent: "center",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Card component="form">
          <CardHeader title={getTranslation(translations, "password.name")} />
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="set-password"
                  fullWidth
                  size="small"
                  type={passwordVisibility ? "text" : "password"}
                  label={getTranslation(
                    translations,
                    "password.label.newpassword"
                  )}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  value={values.password}
                  onChange={({ target }) =>
                    changeHandler("password", target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => setPasswordVisibility((_) => !_)}
                      >
                        {passwordVisibility ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
              {/* <div>
              {getTranslation(translations, "changepassword.info.subtitle")}
            </div> */}
              <Grid item xs={12}>
                <TextField
                  id="confirm-password"
                  fullWidth
                  size="small"
                  type={confirmPasswordVisibility ? "text" : "password"}
                  label={getTranslation(
                    translations,
                    "password.label.confirmpassword"
                  )}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  value={values.confirmPassword}
                  onChange={({ target }) =>
                    changeHandler("confirmPassword", target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => setConfirmPasswordVisibility((_) => !_)}
                      >
                        {confirmPasswordVisibility ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button
              id="set-password-button"
              type="submit"
              size="small"
              variant="contained"
              disabled={!isValid}
              onClick={(e) => {
                e.preventDefault();
                submitData(values);
              }}
            >
              {getTranslation(translations, "generic.button.savechanges")}
            </Button>
          </CardActions>
        </Card>
        <Typography variant="body2" color="text.secondary">Password must contain at least 12 characters, an Uppercase letter, a number and a special character</Typography>
      </Container>
    </Box>
  );
};
export default Password;
