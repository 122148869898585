import React, { useCallback, useMemo } from "react";
import { ExtendedTask } from "model/statisticsModel";
import { Box, Card, Typography } from "@mui/material";
import dayjs from "dayjs";
import { getTranslation } from "common";
import { useDurationFormat } from "hooks";
import { formatDuration } from "common/utilities";

export const StandardTooltipContent: React.FC<{
  formatDate: (value: Date, includeTime?: boolean) => string;
  task: ExtendedTask;
  fontSize: string;
  fontFamily: string;
}> = ({ task, fontSize, fontFamily, formatDate }) => {
  const { replaceDatePlaceHolder, translations } = useDurationFormat();
  const style = {
    fontSize,
    fontFamily,
  };
  const isModel = task.taskType === "processmodel";
  const isProcess = task.taskType === "process";
  const isTask = !isProcess && !isModel;

  return (
    <Box
      component={Card}
      sx={{
        p: 2,
        boxShadow:
          "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
      }}
    >
      <Typography sx={{ ...style, fontWeight: "bold" }}>
        {isModel
          ? `${getTranslation(translations, "pm.label.caption")}: ${task.name}`
          : isProcess
            ? `${getTranslation(translations, "generic.processes")}: ${task.name}`
            : `${getTranslation(translations, "generic.task.caption")}: ${task.name}`}
      </Typography>

      {!isModel && (
        <React.Fragment>
          <Typography sx={{ ...style }}>
            {getTranslation(translations, "pm.processstart.start.label")}:{formatDate(task.start, true)}
          </Typography>
          <Typography sx={{ ...style }}>
            {getTranslation(translations, "generic.end.caption")}:{formatDate(task.end, true)}
          </Typography>
        </React.Fragment>
      )}
      {isTask && (
        <Typography sx={{ ...style }}>
          {getTranslation(translations, "generic.user")}:{task.userfullname}
        </Typography>
      )}
      {isTask && (
        <Typography sx={{ ...style }}>
          {getTranslation(translations, "generic.waitingtime.caption")}:{" "}
          {replaceDatePlaceHolder(
            dayjs
              .duration(task.waitingSeconds || 0, "seconds")
              .format(formatDuration(task.waitingSeconds))
          )}
        </Typography>
      )}
      {!isModel && (
        <Typography sx={{ ...style }}>
          {getTranslation(translations, "generic.workingtime.caption")}:{" "}
          {replaceDatePlaceHolder(
            dayjs
              .duration(task.workingSeconds || 0, "seconds")
              .format(formatDuration(task.workingSeconds))
          )}
        </Typography>
      )}
    </Box>
  );
};

export default StandardTooltipContent;
