import { ArrowDropDown } from "@mui/icons-material";
import {
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  Grow,
  List,
  ListItemButton,
  Paper,
  Popper,
  Stack,
} from "@mui/material";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import { getTranslation } from "common";
import { useTranslations } from "hooks";

type CustomPickerDayProps = PickersDayProps<dayjs.Dayjs> & {
  dayIsBetween?: boolean;
  isFirstDay?: boolean;
  isLastDay?: boolean;
};

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:hover": {
      backgroundColor: `${theme.palette.primary.dark}!important`,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
})) as React.ComponentType<CustomPickerDayProps>;
export type DateRangePickerValue = Record<"start" | "end", dayjs.Dayjs | null>;
interface DateRangePickerMenuProps {
  onChange: (interval: DateRangePickerValue) => void;
  value: DateRangePickerValue;
  showDefaultIntervals?: boolean;
}
const DateRangePickerMenu: React.FC<DateRangePickerMenuProps> = ({
  onChange,
  value,
  showDefaultIntervals = false,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const translations = useTranslations();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const oneDayLabel = getTranslation(translations, "generic.oneDay.caption");
  const yesterdayLabel = getTranslation(translations, "generic.yesterday.caption");
  const todayLabel = getTranslation(translations, "generic.today.caption");
  const thisYearFromJanuaryLabel = getTranslation(translations, "generic.thisyear.caption");
  const lastYearLabel = getTranslation(translations, "generic.lastcalendaryear.caption");
  const lastTwelveMonthsLabel = getTranslation(translations, "generic.last12months.caption");
  const thisWeekLabel = getTranslation(translations, "generic.thisweek.caption");
  const lastWeekLabel = getTranslation(translations, "generic.lastweek.caption");
  const lastSevenDaysLabel = getTranslation(translations, "generic.last7days.caption");
  const last28DaysLabel = getTranslation(translations, "generic.last28days.caption");
  const last30DaysLabel = getTranslation(translations, "generic.last30days.caption");
  const last90DaysLabel = getTranslation(translations, "generic.last90days.caption");
  const [{ start, end }, setInterval] = useState<DateRangePickerValue>(value);
  const selection = useRef<boolean>(false);
  const direction = useRef<"down" | "up" | null>(null);
  const setStart = (v: dayjs.Dayjs) => setInterval((_) => ({ ..._, start: v }));
  const setEnd = (v: dayjs.Dayjs) => setInterval((_) => ({ ..._, end: v }));
  const [label, setLabel] = useState<string>(() => {
    const dateInterval = localStorage.getItem("dateInterval");
    if (dateInterval) {
      try {
        const { start, end } = JSON.parse(dateInterval);
        const dayStart = dayjs(start).startOf("day");
        const dayEnd = dayjs(end).endOf("day");

        if (dayStart.isSame(dayjs().startOf("day")) && dayEnd.isSame(dayjs().endOf("day"))) {
          return todayLabel;
        } else if (
          dayStart.isSame(dayjs().subtract(1, "day").startOf("day")) &&
          dayEnd.isSame(dayjs().subtract(1, "day").endOf("day"))
        ) {
          return yesterdayLabel;
        } else if (
          dayStart.isSame(dayjs().startOf("week").startOf("day")) &&
          dayEnd.isSame(dayjs().endOf("day"))
        ) {
          return thisWeekLabel;
        } else if (
          dayStart.isSame(dayjs().startOf("week").subtract(1, "week").startOf("day")) &&
          dayEnd.isSame(dayjs().startOf("week").subtract(1, "day").endOf("day"))
        ) {
          return lastWeekLabel;
        } else if (
          dayStart.isSame(dayjs().subtract(6, "day").startOf("day")) &&
          dayEnd.isSame(dayjs().endOf("day"))
        ) {
          return lastSevenDaysLabel;
        } else if (
          dayStart.isSame(dayjs().subtract(27, "day").startOf("day")) &&
          dayEnd.isSame(dayjs().endOf("day"))
        ) {
          return last28DaysLabel;
        } else if (
          dayStart.isSame(dayjs().subtract(29, "day").startOf("day")) &&
          dayEnd.isSame(dayjs().endOf("day"))
        ) {
          return last30DaysLabel;
        } else if (
          dayStart.isSame(dayjs().subtract(89, "day").startOf("day")) &&
          dayEnd.isSame(dayjs().endOf("day"))
        ) {
          return last90DaysLabel;
        } else if (
          dayStart.isSame(dayjs().startOf("month").subtract(12, "month").startOf("day")) &&
          dayEnd.isSame(dayjs().startOf("month").subtract(1, "day").endOf("day"))
        ) {
          return lastTwelveMonthsLabel;
        } else if (
          dayStart.isSame(dayjs().subtract(1, "year").startOf("year").startOf("day")) &&
          dayEnd.isSame(dayjs().subtract(1, "year").endOf("year").endOf("day"))
        ) {
          return lastYearLabel;
        } else if (
          dayStart.isSame(dayjs().startOf("year").startOf("day")) &&
          dayEnd.isSame(dayjs().endOf("day"))
        ) {
          return thisYearFromJanuaryLabel;
        } else {
          return `${dayEnd.diff(dayStart, "d") + 1} ${getTranslation(
            translations,
            "generic.days"
          )}`
        }
      } catch {
        console.log("check interval in localstorage");
      }
    }
    return todayLabel;
  });

  useEffect(() => {
    if (!open) {
      selection.current = false;
      direction.current = null;
    } else {
      setInterval(value);
    }
  }, [open, value]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const sendData = () => {
    if (start && end) {
      onChange({
        start,
        end: end.add(1, "day").subtract(1, "millisecond"),
      });
      // console.log("start:", start)
      // console.log("end", end)
      if (start.isSame(end, "D")) {
        if (start.isSame(dayjs(), "D")) {
          setLabel(todayLabel);
        } else if (start.isSame(dayjs().subtract(1, "day"), "D")) {
          setLabel(yesterdayLabel);
        } else {
          setLabel(oneDayLabel);
        }
      } else {
        setLabel(`${end.diff(start, "d") + 1} ${getTranslation(
          translations,
          "generic.days"
        )}`);
      }
    }
  };
  const RenderWeekPickerDay = ({
    day,
    ...pickersDayProps
  }: PickersDayProps<dayjs.Dayjs>) => {
    if (!start && !end) {
      return (
        <PickersDay
          disableMargin
          {...pickersDayProps}
          day={day}
          onClick={() => {
            setInterval({
              start: day,
              end: null,
            });
            selection.current = true;
            direction.current = "up";
          }}
        />
      );
    }
    const dayIsBetween =
      day.isAfter(start?.subtract(1, "D")) && day.isBefore(end?.add(1, "D"));
    const isFirstDay = day.isSame(start, "D");
    const isLastDay = day.isSame(end, "D");
    return (
      <CustomPickersDay
        {...pickersDayProps}
        day={day}
        aria-label="custom"
        onMouseEnter={() => {
          if (selection.current) {
            if (direction.current === "up") {
              if (day.isBefore(start)) {
                direction.current = "down";
                setInterval({
                  start: day,
                  end: start,
                });
              } else {
                setEnd(day);
              }
            } else if (direction.current === "down") {
              if (day.isAfter(end)) {
                direction.current = "up";
                setInterval({
                  start: end,
                  end: day,
                });
              } else {
                setStart(day);
              }
            }
          }
        }}
        onClick={() => {
          // console.log("Selected day:", day)
          if (!selection.current) {
            setInterval({
              start: day,
              end: null,
            });
            selection.current = true;
            direction.current = "up";
          } else {
            // console.log("start", start)
            // console.log("end", end)

            selection.current = false;
            sendData();
          }
        }}
        disableMargin
        dayIsBetween={Boolean(dayIsBetween)}
        isFirstDay={Boolean(isFirstDay)}
        isLastDay={Boolean(isLastDay)}
      />
    );
  };
  const setIntervalAndChange = (interval: DateRangePickerValue) => {
    setInterval(interval);
    onChange(interval);
  };

  return (
    <React.Fragment>
      <Stack direction="row" spacing={1} alignItems="center">
        <Chip label={label} size="small" />
        <Button
          id="date-range-picker"
          disableElevation
          size="small"
          ref={anchorRef}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          onClick={handleToggle}
          endIcon={<ArrowDropDown />}
        >
          {value.start && value.end
            ? `${value.start.format("DD/MM/YYYY")} - ${value.end.format(
              "DD/MM/YYYY"
            )}`
            : getTranslation(
              translations,
              "generic.no_selected_interval.caption"
            )}
        </Button>
      </Stack>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper elevation={5}>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <Stack direction="row">
                  {showDefaultIntervals && (
                    <List sx={{ maxHeight: "334px", overflow: "auto" }}>
                      <ListItemButton
                        onClick={() => {
                          setIntervalAndChange({
                            start: dayjs().startOf("day"),
                            end: dayjs().endOf("day"),
                          });
                          setLabel(todayLabel);
                        }}
                      >
                        {todayLabel}
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => {
                          setIntervalAndChange({
                            start: dayjs().add(-1, "day").startOf("day"),
                            end: dayjs().add(-1, "day").endOf("day"),
                          });
                          setLabel(yesterdayLabel);
                        }}
                      >
                        {yesterdayLabel}
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => {
                          setIntervalAndChange({
                            start: dayjs().startOf("week").startOf("day"),
                            end: dayjs().endOf("day"),
                          });
                          setLabel(thisWeekLabel);
                        }}
                      >
                        {thisWeekLabel}
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => {
                          setIntervalAndChange({
                            start: dayjs()
                              .startOf("week")
                              .add(-1, "week")
                              .startOf("day"),
                            end: dayjs()
                              .startOf("week")
                              .add(-1, "day")
                              .endOf("day"),
                          });
                          setLabel(lastWeekLabel);
                        }}
                      >
                        {lastWeekLabel}
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => {
                          setIntervalAndChange({
                            start: dayjs().add(-6, "day").startOf("day"),
                            end: dayjs().endOf("day"),
                          });
                          setLabel(lastSevenDaysLabel);
                        }}
                      >
                        {lastSevenDaysLabel}
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => {
                          setIntervalAndChange({
                            start: dayjs().add(-27, "day").startOf("day"),
                            end: dayjs().endOf("day"),
                          });
                          setLabel(last28DaysLabel);
                        }}
                      >
                        {last28DaysLabel}
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => {
                          setIntervalAndChange({
                            start: dayjs().add(-29, "day").startOf("day"),
                            end: dayjs().endOf("day"),
                          });
                          setLabel(last30DaysLabel);
                        }}
                      >
                        {last30DaysLabel}
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => {
                          setIntervalAndChange({
                            start: dayjs().add(-89, "day").startOf("day"),
                            end: dayjs().endOf("day"),
                          });
                          setLabel(last90DaysLabel);
                        }}
                      >
                        {last90DaysLabel}
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => {
                          setIntervalAndChange({
                            start: dayjs()
                              .startOf("month")
                              .add(-12, "month")
                              .startOf("day"),
                            end: dayjs()
                              .startOf("month")
                              .add(-1, "day")
                              .endOf("day"),
                          });
                          setLabel(lastTwelveMonthsLabel);
                        }}
                      >
                        {lastTwelveMonthsLabel}
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => {
                          setIntervalAndChange({
                            start: dayjs()
                              .add(-1, "year")
                              .startOf("year")
                              .startOf("day"),
                            end: dayjs()
                              .add(-1, "year")
                              .endOf("year")
                              .endOf("day"),
                          });
                          setLabel(lastYearLabel);
                        }}
                      >
                        {lastYearLabel}
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => {
                          setIntervalAndChange({
                            start: dayjs().startOf("year").startOf("day"),
                            end: dayjs().endOf("day"),
                          });
                          setLabel(thisYearFromJanuaryLabel);
                        }}
                      >
                        {thisYearFromJanuaryLabel}
                      </ListItemButton>
                    </List>
                  )}
                  <Divider flexItem orientation="vertical" />
                  <StaticDatePicker
                    disableFuture
                    displayStaticWrapperAs="desktop"
                    showDaysOutsideCurrentMonth
                    sx={{
                      ".MuiDayCalendar-header .MuiDayCalendar-weekDayLabel": {
                        m: 0,
                      },
                    }}
                    slots={{
                      day: (props: PickersDayProps<dayjs.Dayjs>) => (
                        <RenderWeekPickerDay {...props} selected={false} />
                      ),
                    }}
                  />
                </Stack>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
};

export default DateRangePickerMenu;
