import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { getTranslation } from "common";
import { useOrganizations, useSnackbar, useTranslations, useUser } from "hooks";
import React, { useState } from "react";
import {
  Add,
  Clear,
  ExitToApp,
  ManageAccounts,
  PeopleAlt,
  PersonRemove,
} from "@mui/icons-material";
import HODCheckbox from "./HODCheckbox";
import { GroupsMenu, RolesMenu } from "./menus";
import { User } from "./UserItem";
import { groupsApi, organizationsApi, rolesApi } from "api";
import AdminCheckbox from "./AdminCheckbox";

import AvatarRem from "./AvatarRem";
import UserAvatar from "./UserAvatar";
import RemoveUserPopUp from "./dialogs/RemoveUserPopUp";
import { useDispatch } from "react-redux";
import { removeSelectedOrganization } from "Redux/organizationReducer";

interface UserSelectedPanelProps {
  userSelected: User;
  organizationId: number;
  inMobile?: boolean;
  userLoggedIsAdmin: boolean;
  getUserUntilPage?: (userSelected: User) => Promise<void>;
  refresh: () => void | null;
}
export interface GroupAssociation {
  creation: string;
  hod: boolean;
  icon: string | null;
  id: number;
  idorganization: number;
  name: string;
}
export interface RoleAssociation {
  creation: string;
  icon: string | null;
  id: number;
  idorganization: number;
  name: string;
}

const UserSelectedPanel: React.FC<UserSelectedPanelProps> = ({
  userSelected,
  userLoggedIsAdmin,
  organizationId,
  inMobile,
  getUserUntilPage,
  refresh,
}) => {
  const translations = useTranslations();
  const [groupMenu, setGroupMenu] = useState<null | HTMLElement>(null);
  const [roleMenu, setRoleMenu] = useState<null | HTMLElement>(null);
  const { sendSnack } = useSnackbar();
  const user = useUser();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, getOrganizations] = useOrganizations();
  const [shouldRemoveUserPopup, setShouldRemoveUserPopup] =
    useState<boolean>(false);
  const linkGroup = async (id: number) => {
    /* /groups/($id)/users/($idutente) POST/DELETE associa/dissocia */
    try {
      const createRes = await groupsApi.post(`${id}/users/${userSelected.id}`);
      getUserUntilPage && (await getUserUntilPage(userSelected));
      sendSnack({
        message: `${createRes.data.responsemessage}`,
        type: "success",
      });
      setGroupMenu(null);
    } catch {
    } finally {
    }
  };
  const unlinkGroup = async (id: number) => {
    /* /groups/($id)/users/($idutente) POST/DELETE associa/dissocia */
    try {
      const deleteApi = await groupsApi.delete(
        `${id}/users/${userSelected.id}`
      );
      getUserUntilPage && (await getUserUntilPage(userSelected));
      sendSnack({
        message: `${deleteApi.data.responsemessage}`,
        type: "success",
      });
      setGroupMenu(null);
    } catch {
    } finally {
    }
  };
  const linkRole = async (id: number) => {
    try {
      const createRes = await rolesApi.post(`${id}/users/${userSelected.id}`);
      getUserUntilPage && (await getUserUntilPage(userSelected));
      sendSnack({
        message: `${createRes.data.responsemessage}`,
        type: "success",
      });
      setRoleMenu(null);
    } catch {
    } finally {
    }
  };
  const unlinkRole = async (id: number) => {
    /* /roles/($id)/users/($idutente) POST/DELETE associa/dissocia */
    try {
      const deleteApi = await rolesApi.delete(`${id}/users/${userSelected.id}`);
      getUserUntilPage && (await getUserUntilPage(userSelected));
      sendSnack({
        message: `${deleteApi.data.responsemessage}`,
        type: "success",
      });
      setRoleMenu(null);
    } catch {
    } finally {
    }
  };

  const handleChecked = async (id: number, checked: boolean) => {
    const updateApi = await groupsApi.put(
      `${id}/users/${userSelected.id}/hod/${checked}`
    );
    getUserUntilPage && (await getUserUntilPage(userSelected));
    sendSnack({
      message: `${updateApi.data.responsemessage}`,
      type: "success",
    });
  };
  const handleCheckedAdmin = async (id: number, checked: boolean) => {
    let response;
    // api works that to set admin to true you have to call put
    if (checked) {
      response = await organizationsApi.post(
        `${organizationId}/admins/${id}/admin`
      );
    } else {
      const { data } = await organizationsApi.get(`${organizationId}/users/v2`);
      if ((data.users as User[]).filter((_) => _.administrator).length <= 1) {
        sendSnack({
          message: getTranslation(translations, "organizations.error.last_user"),
          type: "error"
        });
        return;
      } else {
        // for admin false call delete
        response = await organizationsApi.delete(
          `${organizationId}/admins/${id}/admin`
        );
      }
    }
    // setAdmin(checked);
    getUserUntilPage && (await getUserUntilPage(userSelected));
    sendSnack({
      message: `${response.data.responsemessage}`,
      type: "success",
    });
  };
  const dispatch = useDispatch();
  const handleRemoveUser = async (id: number) => {
    try {
      const deleteApi = await organizationsApi.delete(
        `/${organizationId}/users/${id}`
      );
      sendSnack({
        message: `${deleteApi.data.responsemessage}`,
        type: "success",
      });
      if (user?.userId === id) {
        // selezionare organizzazione di cui fai parte o tua
        // nel caso in cui sei uscito dall'ultima organizzazione
        // seleziona null
        // oppure rimuovo la selected

        // per ora rimuovo in quanto non ho la lista di organizzazioni

        dispatch(removeSelectedOrganization({}));
      } else {
        getUserUntilPage && (await getUserUntilPage(userSelected));
      }
      setShouldRemoveUserPopup(false);

      // getFirstUsers();
    } catch {
    } finally {
      getOrganizations();
    }
  };
  return (
    <React.Fragment>
      {inMobile || <Divider flexItem orientation="vertical" sx={{ m: 1 }} />}
      <Stack height="100%" maxWidth={300} flex={1}>

        <Box height={"8%"}>
          <Stack direction="column" justifyContent="flex-start" >
            <UserAvatar showUsername user={userSelected as any} />
            <Typography variant="body2" sx={{ fontSize: '0.8em', color: 'gray', ml: 3 }}>
              {userSelected.email}
            </Typography>
          </Stack>
        </Box>

        <Box height={"8%"}>
          <Stack
            spacing={2}
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            {userLoggedIsAdmin && (
              <AdminCheckbox
                initialChecked={userSelected.administrator}
                onChange={async (checked) => {
                  await handleCheckedAdmin(userSelected.id, checked);
                  getOrganizations();
                }}
              />
            )}
            {(userLoggedIsAdmin || user?.userId === userSelected.id) && (
              <Tooltip
                title={
                  user?.userId === userSelected.id
                    ? getTranslation(translations, "generic.exit_organization.caption")
                    : getTranslation(translations, "generic.remove_user_from_organization.caption")
                }
              >
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShouldRemoveUserPopup(true);
                  }}
                  startIcon={
                    user?.userId === userSelected.id ? (
                      <ExitToApp />
                    ) : (
                      <PersonRemove />
                    )
                  }
                  size="small"
                  variant="contained"
                >
                  {user?.userId === userSelected.id
                    ? getTranslation(translations, "generic.exit")
                    : getTranslation(translations, "generic.remove")}
                </Button>
              </Tooltip>
            )}
          </Stack>
        </Box>
        <Divider flexItem />
        <Stack height="calc(42% - 1px)" spacing={1}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            justifyContent="space-between"
          >
            <Typography variant="subtitle1">
              {getTranslation(translations, "groups.name")}
            </Typography>
            {userLoggedIsAdmin && (
              <IconButton
                id="add-group"
                onClick={({ currentTarget }) => setGroupMenu(currentTarget)}
                size="medium"
                color="info"
              >
                <Add fontSize="medium" />
              </IconButton>
            )}
          </Stack>
          <List
            id="associated-groups"
            sx={{ height: "100%", overflow: "auto" }}
            disablePadding
          >
            {/* {loading && (
              <Stack alignItems="center" justifyContent="center">
              <CircularProgress />
              </Stack>
            )} */}
            {userSelected?.groups?.map(({ name, icon, id, hod }) => (
              <ListItem alignItems="center" key={`associated-groups-${id}`}>
                <ListItemAvatar sx={{ height: "40px", width: "40px" }}>
                  <AvatarRem icon={icon || ""}>
                    <PeopleAlt />
                  </AvatarRem>
                </ListItemAvatar>
                <ListItemText>
                  {name}
                  {userLoggedIsAdmin && (
                    <HODCheckbox
                      disabled={!userLoggedIsAdmin}
                      initialChecked={hod}
                      onChange={async (checked) =>
                        await handleChecked(id, checked)
                      }
                    />
                  )}
                </ListItemText>
                <ListItemSecondaryAction>
                  {userLoggedIsAdmin && (
                    <IconButton
                      id={`remove-group-${id}`}
                      size="medium"
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        unlinkGroup(id);
                      }}
                    >
                      <Clear fontSize="medium" />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Stack>
        <Divider flexItem />
        <Stack height="calc(42% - 1px)" spacing={1}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={2}
            justifyContent="space-between"
          >
            <Typography variant="subtitle1">
              {getTranslation(translations, "roles.name")}
            </Typography>
            {userLoggedIsAdmin && (
              <IconButton
                onClick={({ currentTarget }) => setRoleMenu(currentTarget)}
                id="add-role"
                size="medium"
                color="info"
              >
                <Add fontSize="medium" />
              </IconButton>
            )}
          </Stack>
          <List
            id="associated-roles"
            sx={{ height: "100%", overflow: "auto" }}
            disablePadding
          >
            {/* {loading && (
              <Stack alignItems="center" justifyContent="center">
                <CircularProgress />
              </Stack>
            )} */}
            {userSelected?.roles?.map(({ name, icon, id }) => (
              <ListItem key={`associated-roles-${id}`}>
                <ListItemAvatar sx={{ height: "40px", width: "40px" }}>
                  <AvatarRem icon={icon || ""}>
                    <ManageAccounts />
                  </AvatarRem>
                </ListItemAvatar>
                <ListItemText>{name}</ListItemText>
                <ListItemSecondaryAction>
                  {userLoggedIsAdmin && (
                    <IconButton
                      id={`remove-role-${id}`}
                      size="medium"
                      color="error"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        unlinkRole(id);
                      }}
                    >
                      <Clear fontSize="medium" />
                    </IconButton>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Stack>
        <GroupsMenu
          onClose={() => setGroupMenu(null)}
          associated={userSelected.groups}
          onLink={linkGroup}
          anchor={groupMenu}
          organizationId={organizationId}
        />
        <RolesMenu
          onClose={() => setRoleMenu(null)}
          associated={userSelected.roles}
          onLink={linkRole}
          anchor={roleMenu}
          organizationId={organizationId}
        />
      </Stack>
      {shouldRemoveUserPopup && user && (
        <RemoveUserPopUp
          idLoggedUser={user.userId}
          idUser={userSelected?.id}
          handleRemoveUser={handleRemoveUser}
          shouldRemoveUserPopup={shouldRemoveUserPopup}
          setShouldRemoveUserPopup={setShouldRemoveUserPopup}
        />
      )}
    </React.Fragment>
  );
};

export default UserSelectedPanel;
