import { getTranslation } from "common";
import { useCallback, useMemo } from "react";
import useTranslations from "./useTranslations";

const useDurationFormat = () => {
    const translations = useTranslations();
    const dict = useMemo(
        () => ({
            "{$}": getTranslation(translations, "generic.years.caption"),
            "{$$}": getTranslation(translations, "generic.months.caption"),
            "{$$$}": getTranslation(translations, "generic.days.caption"),
            "{$$$$}": getTranslation(translations, "generic.hours.caption"),
            "{$$$$$}": getTranslation(translations, "generic.minutes.caption"),
            "{$$$$$$}": getTranslation(translations, "generic.seconds.caption"),
        }),
        [translations]
    );
    const replaceDatePlaceHolder = useCallback(
        (formattedDate: string) => {
            Object.entries(dict).forEach(([key, value]) => {
                formattedDate = formattedDate.replace(key, value);
            });
            return formattedDate;
        },
        [dict]
    );
    return {translations, replaceDatePlaceHolder}
}

export default useDurationFormat