import React, { useCallback, useMemo } from "react";
import { Box, Typography, Grid } from "@mui/material";
import CardGraphDetail from "./CardGraphDetail";
import dayjs from "dayjs";
import PerformanceViewHeader from "./PerformanceViewHeader";
import _ from "lodash";
import { formatDuration } from "common/utilities";
import { useDurationFormat } from "hooks";
import { SupportedUserDataTypes } from "hooks/useUserDataKeys";
import { getTranslation } from "common";
import { useTranslations } from "hooks";
export interface FlatStatistics {
  created: string;
  designerid: string;
  done: string;
  flownodename: string;
  flownodetype: number;
  idflownode: number;
  idprocess: number;
  idprocesstoken: number;
  idtype: number;
  iduser: number;
  jsontag: null;
  previous: number;
  process_description: string;
  process_enddate: string;
  process_idprocessmodel: number;
  process_startdate: string;
  process_userdata: string;
  processduration: number;
  processmodeldeleted: boolean;
  processmodelname: string;
  startdoing: string;
  state: number;
  taskduration: number;
  timeindoing: number;
  timetotakeover: number;
  userfullname: string;
}

interface PerformanceViewProps {
  data: FlatStatistics[];
  interval: { start: dayjs.Dayjs | null; end: dayjs.Dayjs | null };
}

const PerformanceView: React.FC<PerformanceViewProps> = ({
  data,
  interval,
}) => {
  const { replaceDatePlaceHolder } = useDurationFormat();
  const isValidData = Array.isArray(data) && data.length > 0;
  const translations = useTranslations();

  const renderDuration = ({ value }: { value: unknown }) =>
    replaceDatePlaceHolder(
      dayjs
        .duration((value as number) || 0, "seconds")
        .format(formatDuration(value as number))
    );

  const getUniquesBy = useCallback(
    (
      data: FlatStatistics[],
      key: keyof Omit<Omit<FlatStatistics, "jsontag">, "processmodeldeleted">
    ) => {
      if (!isValidData) return [];
      return Object.values(
        data.reduce((acc, a) => {
          const id = a[key];
          acc[id] = a;
          return acc;
        }, {} as Record<number | string, FlatStatistics>)
      );
    },
    [isValidData]
  );

  const uniqueModels = useMemo(
    () => getUniquesBy(data, "process_idprocessmodel"),
    [data, getUniquesBy]
  );
  const uniqueProcesses = useMemo(
    () => getUniquesBy(data, "idprocess"),
    [data, getUniquesBy]
  );
  const activitiesPerUser = useMemo(
    () =>
      _.zip(
        getUniquesBy(data, "userfullname").map(
          ({ userfullname }) => userfullname
        ),
        getUniquesBy(data, "userfullname").map(({ userfullname }) =>
          data.filter((a) => a.userfullname === userfullname)
        )
      ) as [string, FlatStatistics[]][],
    [data, getUniquesBy]
  );
  const activitiesPerDate = useMemo(
    () => _.groupBy(data, (item) => dayjs(item.process_enddate).format("YYYY-MM-DD")),
    [data]
  );

  if (!isValidData) {
    return (
      //se un giorno verrà cambiato, lasciare lo stesso classname sennò falliscono i test
      <Typography textAlign="center" mt={4} className="no-statistics-data">
        {getTranslation(
          translations,
          "generic.dataset.empty"
        )}
      </Typography>
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      <PerformanceViewHeader data={data} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CardGraphDetail
            interval={interval}
            title={getTranslation(translations, "statistics.processmodel_distribution")}
            chartOptions={{
              dataKey: "value",
              nameKey: "label",
              tooltipFormatter: (value) => `${value}%`,
              data: uniqueModels.map((a) => ({
                label: a.processmodelname,
                value: parseFloat(
                  (
                    (uniqueProcesses.filter(
                      (b) => a.process_idprocessmodel === b.process_idprocessmodel
                    ).length *
                      100) /
                    uniqueProcesses.length
                  ).toFixed(2)
                ),
              })),
              type: "pie",
            }}
            tableOptions={{
              keyGetter: ({ processmodelid }) =>
                `statistic-per-process-model-${processmodelid}`,
              data: uniqueModels.map((a) => ({
                processmodelid: a.process_idprocessmodel,
                processmodelname: a.processmodelname,
                completionCount: uniqueProcesses.filter(
                  (b) => a.process_idprocessmodel === b.process_idprocessmodel
                ).length,
                percentage: `${parseFloat(
                  (
                    (uniqueProcesses.filter(
                      (b) => a.process_idprocessmodel === b.process_idprocessmodel
                    ).length *
                      100) /
                    uniqueProcesses.length
                  ).toFixed(2)
                )}%`
              })),
              columns: [
                {
                  id: "processmodelname",
                  field: "processmodelname",
                  name: `${getTranslation(translations, "pm.research.orderby.modelname")}`,
                },
                {
                  id: "completionCount",
                  field: "completionCount",
                  name: `${getTranslation(translations, "statistics_closed.processes")}`,
                  type: SupportedUserDataTypes.number,
                },
                {
                  id: "percentage",
                  field: "percentage",
                  name: `${getTranslation(translations, "statistics_closed.processes.percentage")}`,
                  type: SupportedUserDataTypes.number,
                },
              ],
            }}
            cardId="model-distribution"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardGraphDetail
            interval={interval}
            title={getTranslation(translations, "statistics_activity.per.user")}
            chartOptions={{
              dataKey: "value",
              nameKey: "label",
              data: activitiesPerUser.map(([userfullname, activities]) => ({
                label: userfullname,
                value: activities.length,
              })),
              type: "pie",
            }}
            tableOptions={{
              data: activitiesPerUser.map(([userfullname, activities]) => ({
                userfullname,
                activityCount: activities.length,
                averageTime:
                  activities.reduce((acc, a) => acc + a.timeindoing, 0) /
                  (activities.length || 1),
              })),
              columns: [
                {
                  id: "userfullname",
                  field: "userfullname",
                  name: `${getTranslation(translations, "generic.user")}`,
                },
                {
                  id: "activityCount",
                  field: "activityCount",
                  name: `${getTranslation(translations, "statistics_number.of.activities")}`,
                  type: SupportedUserDataTypes.number,
                },
                {
                  id: "averageTime",
                  field: "averageTime",
                  name: `${getTranslation(translations, "generic.average.time")}`,
                  type: SupportedUserDataTypes.number,
                  renderCell: renderDuration,
                },
              ],
              keyGetter: ({ userfullname }) =>
                `statistic-per-user-${userfullname}`,
            }}
            cardId="user-activity-distribution"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardGraphDetail
            interval={interval}
            title={getTranslation(translations, "statistics_process.detail")}
            tableOptions={{
              data: uniqueProcesses.map(
                ({
                  idprocess,
                  process_description,
                  processmodelname,
                  processduration,
                }) => ({
                  idprocess,
                  name: process_description,
                  activitiesNumber: data.filter(
                    (a) => a.idprocess === idprocess
                  ).length,
                  processmodelname,
                  totalDuration: processduration,
                })
              ),
              columns: [
                {
                  id: "name",
                  field: "name",
                  name: `${getTranslation(translations, "generic.process")}`,
                },
                {
                  id: "activitiesNumber",
                  field: "activitiesNumber",
                  name: `${getTranslation(translations, "statistics_number.of.activities")}`,
                  type: SupportedUserDataTypes.number,
                },
                {
                  id: "processmodelname",
                  field: "processmodelname",
                  name: `${getTranslation(translations, "statistics_from.model")}`,
                },
                {
                  id: "totalDuration",
                  field: "totalDuration",
                  name: `${getTranslation(translations, "statistics_total.duration")}`,
                  type: SupportedUserDataTypes.number,
                  renderCell: renderDuration,
                },
              ],
              keyGetter: ({ idprocess }) => `process-${idprocess}`,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardGraphDetail
            interval={interval}
            title={getTranslation(translations, "statistics_daily.workload")}
            chartOptions={{
              tooltipFormatter: (label) => dayjs(label as string).format("DD/MM/YYYY"),
              axis: {
                y: {
                  label: getTranslation(translations, "generic.total"), dataKey: "count"
                },
                x: {
                  tickFormatter: (date) => dayjs(date as string).format("DD/MM"), dataKey: "date"
                }
              },
              data: (() => {
                const filteredData = data.filter(
                  (item) =>
                    dayjs(item.done).isAfter(interval.start) &&
                    dayjs(item.done).isBefore(interval.end)
                );

                const aggregatedData = filteredData.reduce<Record<string, number>>(
                  (acc, item) => {
                    const date = dayjs(item.done).format("YYYY-MM-DD");
                    acc[date] = (acc[date] || 0) + 1;
                    return acc;
                  },
                  {}
                );

                const allDates = [];
                let currentDate = interval.start;
                while (
                  currentDate?.isBefore(interval.end) ||
                  currentDate?.isSame(interval.end)
                ) {
                  const formattedDate = currentDate.format("YYYY-MM-DD");
                  allDates.push({
                    date: formattedDate,
                    count: aggregatedData[formattedDate] || 0,
                  });
                  currentDate = currentDate.add(1, "day");
                }

                return allDates;
              })(),
              type: "bars",
            }}
          />
        </Grid>
      </Grid>
      <CardGraphDetail
        interval={interval}
        title={getTranslation(translations, "statistics_daily.average.time")}
        tableOptions={{
          data: Object.entries(activitiesPerDate).map(([date, activities]) => ({
            date,
            processNumber: getUniquesBy(activities, "idprocess").length,
            averageDoingTime:
              activities.reduce((acc, a) => acc + a.timeindoing, 0) /
              activities.length,
            averageWaitingTime:
              activities.reduce((acc, a) => acc + a.timetotakeover, 0) /
              activities.length,
            averageDurationTime:
              activities.reduce((acc, a) => acc + a.timeindoing + a.timetotakeover, 0) /
              activities.length
          })),
          columns: [
            {
              id: "date",
              field: "date",
              name: `${getTranslation(translations, "pm.research.orderby.date")}`,
              type: SupportedUserDataTypes.date
            },
            {
              id: "processNumber",
              field: "processNumber",
              name: `${getTranslation(translations, "statistics_processes.number")}`,
              type: SupportedUserDataTypes.number,
            },
            {
              id: "averageDoingTime",
              field: "averageDoingTime",
              name: `${getTranslation(translations, "statistics_average.working.time")}`,
              type: SupportedUserDataTypes.number,
              renderCell: renderDuration,
            },
            {
              id: "averageWaitingTime",
              field: "averageWaitingTime",
              name: `${getTranslation(translations, "statistics_average.waiting.time")}`,
              type: SupportedUserDataTypes.number,
              renderCell: renderDuration,
            },
            {
              id: "averageDurationTime",
              field: "averageDurationTime",
              name: `${getTranslation(translations, "statistics_average.duration.time")}`,
              type: SupportedUserDataTypes.number,
              renderCell: renderDuration,
            },
          ],
          keyGetter: ({ date }) => `statistic-per-${date}`,
        }}
      />
    </Box>
  );
};

export default PerformanceView;
