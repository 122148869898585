import React, { useEffect } from "react";
import { Cancel, Email } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import DialogHeader from "./DialogHeader";
import { useBreakpoint, useTranslations, useForm } from "hooks";
import { getTranslation } from "common";
import { Validation, isEmail, isRequired } from "hooks/useForm";
interface InviteUserDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (email: string | null) => void;
  loading: boolean
}

const InviteUserDialog: React.FC<InviteUserDialogProps> = ({
  open,
  onClose,
  onSave,
  loading
}) => {
  const translations = useTranslations();
  const isMobile = useBreakpoint("down", "sm");
  const validations: Validation[] = [
    ({ email }) =>
      isRequired(email) || {
        email: getTranslation(translations, "register.fieldrequired"),
      },
    ({ email }) =>
      isEmail(email) || {
        email: getTranslation(translations, "register.valid.Error_after_at"),
      },
  ];
  const { reset, values, changeHandler, touched, errors, isValid } = useForm(
    {
      email: "",
    },
    validations
  );

  useEffect(() => {
    if (!open) {
      reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog fullScreen={isMobile} open={open} onClose={() => onClose()}>
      <DialogHeader onClose={onClose}>
        {getTranslation(translations, "users.inviteuser.label.description")}
      </DialogHeader>
      <DialogContent>
        <Grid
          pt={(_) => _.spacing(1)}
          component="form"
          flex={2}
          spacing={2}
          container
        >
          <Grid item xs={12}>
            <TextField
              id="users-dialog"
              value={values.email}
              onChange={({ target }) => changeHandler("email", target.value)}
              variant="outlined"
              fullWidth
              size="small"
              label={getTranslation(translations, "user.email.label")}
              helperText={touched.email && errors.email}
              error={Boolean(touched.email && errors.email)}
            />
          </Grid>
          <Grid
            item
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            xs={12}
          >
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="flex-end"
              spacing={2}
            >
              <Button
                variant="contained"
                // color="warning"
                startIcon={<Cancel />}
                size="small"
                onClick={() => onClose()}
              >
                {getTranslation(translations, "user.buttoncancel.label")}
              </Button>
              <Button
                id="btn-invite-user"
                variant="contained"
                disabled={!isValid || loading}
                startIcon={<Email />}
                type="submit"
                size="small"
                onDoubleClick={e => e.stopPropagation()}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onSave(values.email.toLowerCase());
                }}
              >
                {getTranslation(translations, "users.button.inviteuser")}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default InviteUserDialog;
